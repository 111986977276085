.contact{
    padding: 25px;
    padding-top: 0px;
}

.paper{
    background-color: #2d3039 !important;
    padding: 10px;
}

.send_btn{
    background-color: yellow;
    color: black;
    font-weight: 600;
    min-width: 150px;
    padding: 10px;
    font-size: 16px;
    border-color: yellow;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 2px black;
}