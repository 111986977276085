.banner{
    background-image: radial-gradient(rgba(41, 41, 41, 0.7),rgba(41, 41, 41, 0.7)), url('../../images/tech.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 200px;
    background-position: center center;
    padding: 40px
}

.banner h1{
    font-size: 40px;
}

.explore_btn{
    background-color: yellow;
    border-color: yellow;
    border: none;
    padding: 10px;
    font-size: 16px;
    min-width: 150px;
    margin-top: 20px;
    font-weight: 500;
    text-transform: uppercase;
    cursor: pointer;
}

.work_ex_info_summary{
    padding: 25px;
    padding-bottom: 0;
    display: flex;
    flex-direction: row;
}