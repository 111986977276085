.container{
    position: relative;
    height: 97vh;
    margin-top: 50px;
}
.left_side_bar{
    position: fixed;
    width: 250px;
    top: 50px;
    bottom: 0;
}

.top_part{
    background-color: #2d3039;
    padding: 15px;
    text-align: center;
}

.top_part h4{
    font-weight: 500;
}

.top_part p{
    color: rgb(160, 160, 160);
    margin-top: -10px;
}

.profile_picture{
    width: 110px;
    border-radius: 60px;
}

.image_container{
    border-radius: 60;
    position: relative;
    width: 110px;
    height: 110px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
}

.skills_summary{
    padding: 15px;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
}

.skill_circular_container{
    text-align: center;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
}

.skill_circular_container p{
    font-size: 13px;
    margin-top: 5px;
}

.linear_skills{
    padding: 15px;
}

.sidebar_footer{
    position: absolute;
    padding: 10px;
    background-color: #2d3039;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.content{
    position: absolute;
    left: 270px;
    min-height: 97vh;
}

@media(max-width: 767px){
    .left_side_bar{
        display: none;
    }
    .content{
        left: 0px;
    }
}