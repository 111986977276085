.text_input{
    background-color: #414141 !important;
    display: flex;
    flex-direction: row;
}

.icon_container{
    background-color: black;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.text_input input{
    width: 100%;
    background-color: #414141 !important;
    border: none;
    outline: none;
    color: white;
    font-size: 16px;
    padding: 10px;
}

.text_input textarea{
    width: 100%;
    background-color: #414141 !important;
    border: none;
    outline: none;
    color: white;
    font-size: 16px;
    padding: 10px;
}