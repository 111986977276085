.nav{
    position: fixed;
    top: 0;
    background-color: #202020;
    left: 0;
    right: 0;
    padding: 10px;
}

.content{
    float: right;
}

.link{
    color: white;
    text-decoration: none;
    margin: 15px;
    font-weight: 500;
    font-size: 16px;
}